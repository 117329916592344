import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "./styled-senac.css";

//component
import BodyCard from "./bodyCard";

//GET FILE BASE64 https://easy64.org/convert-file-to-base64/

export default function Files() {
  const [user, setUser] = useState();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (window.location.search !== "") {
      // var user = window.location.search.split("?");
      const idEvent = searchParams.get("idEvent");
      const idPart = searchParams.get("idPart");
      const native = searchParams.get("native");

      setUser({
        idEvent: idEvent,
        idPart: idPart,
        native: native,
      });
    }
  }, []);
  return (
    <div className="section-files-senac">
      <div className="div-files">
        <BodyCard
          user={user}
          data={
            "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App%2095943/DOCS/miss%C3%A3o%20da%20CNC.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=1700330924&Signature=kGj1A9VGI21Yc%2B39pcobR7EVrKw%3D"
          }
          type={"PDF"}
          fileName="Missão da CNC.pdf"
          title={"Missão da CNC"}
          description={[{ data: "" }]}
          id={"dee9bd4d-4d9d-40c4-ba3e-e340a865cc53"}
          button={"Download"}
        />

        <BodyCard
          user={user}
          data={"https://s3.us-east-1.amazonaws.com/bucket.aws.private/App%2095943/DOCS/Planejamento%20Estrat%C3%A9gico%20e%20atua%C3%A7%C3%A3o%20integrada.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=1700331102&Signature=c4qswLNEYQZ0faQpfM%2FMlN1ZIjc%3D"}
          type={"PDF"}
          fileName="Planejamento estratégico e atuação integrada.pdf"
          title={"Planejamento estratégico e atuação integrada"}
          description={[]}
          id={"09ef45e9-1196-4ace-ba61-3cbe781b4cd7"}
          button={"Download"}
        />

        <BodyCard
          user={user}
          data={
            "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App%2095943/DOCS/Organiza%C3%A7%C3%A3o%20Sindical_DJS.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=1700331130&Signature=adrkr5%2BH2hSNy7JbbbIUdgwcHL4%3D"
          }
          type={"PDF"}
          fileName="Organização Sindical.pdf"
          title={"Organização Sindical"}
          description={[{ data: "" }]}
          id={"72b4df0d-8aa1-4115-9fcb-e87b2ecfc5f6"}
          button={"Download"}
        />

        <BodyCard
          user={user}
          data={
            "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App%2095943/DOCS/DIVIS%C3%83O%20DE%20RELA%C3%87%C3%95ES%20INTERNACIONAIS.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=1700331174&Signature=8kpevfUmepoRcdsameMu16nxnxM%3D"
          }
          type={"PDF"}
          fileName="Divisão de relações internacionais.pdf"
          title={"Divisão de relações internacionais"}
          description={[{ data: "" }]}
          id={"6985a853-c9d9-4fb1-be52-4b4f547b62ac"}
          button={"Download"}
        />
        <BodyCard
          user={user}
          data={
            "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App%2095943/DOCS/Contribui%C3%A7%C3%B5es%20sociais%20Sesc%20e%20Senac.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=1700331191&Signature=19pz2FypG6YZiuvamUUcMv2RaLw%3D"
          }
          type={"PDF"}
          fileName="Contribuições sociais Sesc e Senac.pdf"
          title={"Contribuições sociais Sesc e Senac"}
          description={[{ data: "" }]}
          id={"eb8f56d5-1ed3-4add-b11e-96ea80e161cf"}
          button={"Download"}
        />
        <BodyCard
          user={user}
          data={
            "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App%2095943/DOCS/Conhecendo%20o%20Sistema%20Com%C3%A9rcio_SESC%20e%20SENAC.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=1700331221&Signature=OuZ0osfRVkHD84Ia2lddqrMNiQ4%3D"
          }
          type={"PDF"}
          fileName="Conhecendo o sistema comércio Sesc e Senac.pdf"
          title={"Conhecendo o sistema comércio Sesc e Senac"}
          description={[{ data: "" }]}
          id={"2e7729de-24c5-4bd4-91bb-657c172d7780"}
          button={"Download"}
        />
        <BodyCard
          user={user}
          data={
            "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App%2095943/DOCS/Atua%C3%A7%C3%A3o%20Integrada.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=1700331257&Signature=jAMCzNxTqGTys7Ash30LIE3BHrg%3D"
          }
          type={"PDF"}
          fileName="Atuação integrada.pdf"
          title={"Atuação integrada"}
          description={[{ data: "" }]}
          id={"b7b132ff-fe7a-4a3b-9ae7-9a723eca719c"}
          button={"Download"}
        />
        <BodyCard
          user={user}
          data={
            "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App%2095943/DOCS/APRESENTA%C3%87%C3%83O%20PESQUISA%20DE%20IMAGEM%202022.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=1700331281&Signature=6PhwfhCmJTUq9LLdfpNj%2FoQQkiA%3D"
          }
          type={"PDF"}
          fileName="Pesquisa e imagem.pdf"
          title={"Pesquisa e imagem"}
          description={[{ data: "" }]}
          id={"e03b1dec-2db6-435d-8960-23ad242d572e"}
          button={"Download"}
        />
      </div>
    </div>
  );
}
