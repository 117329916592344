import React, { useState, useEffect } from "react";
import "./styled.css";
import { isIOS } from "react-device-detect";

import { postDownload } from "../../services/download";

export default function BodyCard(props) {
  const [download, setDownload] = useState(false);
  async function send() {
    // enviar dados pra api
    await postDownload(
      props.user.idEvent,
      props.user.idPart,
      props.id,
      props.fileName
    )
      .then((response) => {})
      .catch((error) => {
        // console.log("erro", error);
      });

    //se for link externo e ios
    if (download) {
      window.location = props.data;
      return;
    } else {
      if (props.type === "Link" || props.type === "E-book" || props.type === "Quiz" || props.type === "Podcast") {
        var link = document.createElement("a");
        link.href = props.data;
        link.target = "_blank";
        link.click();
      } else {
        // verificar se está web ou mobile pelo parâmetro
        if (props.user.native) {
          // se tiver o paramêtro passado pelo app, baixa pelo app
          window.postMessage(
            JSON.stringify({
              message: "base64ToDownload",
              data: props.data,
              name: props.fileName,
            }),
            "*"
          );
          window.parent.ReactNativeWebView.postMessage(
            JSON.stringify({
              message: "base64ToDownload",
              data: props.data,
              name: props.fileName,
            })
          );
        } else {
          // se n tiver o parâmetro, baixa pelo web
          var link = document.createElement("a");
          link.download = props.fileName;
          link.target = "_blank";
          link.href = props.data;
          link.click();
        }
      }
    }
  }

  useEffect(() => {
    if (isIOS) {
      if (props.type === "Link" || props.type === "E-book" || props.type === "Quiz" || props.type === "Podcast") {
        setDownload(true);
      }
    }
  }, []);

  return (
    <div className="card-files">
      <div className="items-file">
        <div>
          <h1>{props.type}</h1>
          <h2>{props.title}</h2>
          {props.description.map((desc) => (
            <p className="subtitle-files">{desc.data}</p>
          ))}
        </div>

        <a className="btn-download" onClick={() => send()}>
          {props.button}
        </a>
      </div>
    </div>
  );
}
