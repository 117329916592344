import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "./styled.css";

//component
import BodyCard from "./bodyCard";

//GET FILE BASE64 https://easy64.org/convert-file-to-base64/

export default function Danone() {
  const [user, setUser] = useState();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (window.location.search !== "") {
      // var user = window.location.search.split("?");
      const idEvent = searchParams.get("idEvent");
      const idPart = searchParams.get("idPart");
      const native = searchParams.get("native");

      setUser({
        idEvent: idEvent,
        idPart: idPart,
        native: native,
      });
    }
  }, []);
  return (
    <div className="section-files-danone">
      <div className="div-files">
        <BodyCard
          user={user}
          data={
            "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_91749/Videos/Opening%20by%20Antoine%20-%20Legendado%20v1.mp4?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=1698358340&Signature=QSaUnMpxbpIW4KUHfHBDnKCU3us%3D"
          }
          type={"Vídeo"}
          fileName="Video Abertura"
          title={"Vídeo Abertura"}
          description={[{ data: "" }]}
          id={"e15ffb17-a411-4490-9a98-a6a45b6ec31c"}
          button={"Assistir"}
        />
        <BodyCard
          user={user}
          data={
            "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_91749/Videos/WEAREDANONE%20v5.mp4?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=1698265098&Signature=KvXLUkTSpRXnO6pN3eUwNEW63Ds%3D"
          }
          type={"Vídeo"}
          fileName="Somos Danone"
          title={"Somos Danone"}
          description={[{ data: "" }]}
          id={"9d64e002-ca4c-4244-920d-b06175664bf4"}
          button={"Assistir"}
        />
        <BodyCard
          user={user}
          data={
            "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_91749/Videos/Dual%20Project%20-%20Legendado%20v1.mp4?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=1698267520&Signature=pDvyKKqDcYJ3SzT%2F6Okj6%2B5zCW0%3D"
          }
          type={"Vídeo"}
          fileName="Projeto Duplo"
          title={"Projeto Duplo"}
          description={[{ data: "" }]}
          id={"9c32bdb9-f839-4b14-bafa-154c2e8c884c"}
          button={"Assistir"}
        />
        <BodyCard
          user={user}
          data={
            "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_91749/Videos/Renew%20Danone%20legendado%20v2.mp4?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=1698267547&Signature=2NwldLi9FrTm43M1wDaWR4EJAvk%3D"
          }
          type={"Vídeo"}
          fileName="Renew Danone"
          title={"Renew Danone"}
          description={[{ data: "" }]}
          id={"8954d0f7-e5d7-48a0-9d33-3031b692255c"}
          button={"Assistir"}
        />
        <BodyCard
          user={user}
          data={
            "https://youtu.be/dRmbTSbFLPc"
          }
          type={"Vídeo"}
          fileName="O Discurso de Marselha - Episódio 1"
          title={"O Discurso de Marselha - Episódio 1"}
          description={[{ data: "" }]}
          id={"a4859caa-bcdd-4cd9-a513-55c8eac8cd40"}
          button={"Assistir"}
        />
        <BodyCard
          user={user}
          data={
            "https://youtu.be/DwQ1pINGGeY"
          }
          type={"Vídeo"}
          fileName="O Discurso de Marselha - Episódio 2"
          title={"O Discurso de Marselha - Episódio 2"}
          description={[{ data: "" }]}
          id={"83909e70-027c-4725-a7b0-b7a66049d7ad"}
          button={"Assistir"}
        />
        <BodyCard
          user={user}
          data={
            "https://youtu.be/C0nheDLizK8"
          }
          type={"Vídeo"}
          fileName="O Discurso de Marselha - Episódio 3"
          title={"O Discurso de Marselha - Episódio 3"}
          description={[{ data: "" }]}
          id={"d8305d16-afbe-4f54-9927-07dfc9f8afb2"}
          button={"Assistir"}
        />
        <BodyCard
          user={user}
          data={
            "https://youtu.be/R_uuznnHMFs"
          }
          type={"Vídeo"}
          fileName="O Discurso de Marselha - Episódio 4"
          title={"O Discurso de Marselha - Episódio 4"}
          description={[{ data: "" }]}
          id={"63980436-7239-458d-827d-1838bccadfdc"}
          button={"Assistir"}
        />

      </div>
    </div>
  );
}
