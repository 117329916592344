import writeXlsxFile from 'write-excel-file';
import { getDownloads } from '../../services/reports';

export async function createXlsx({idEvent, countData}) {
  try {
    const schema = [
      {
        column: 'Nome do Arquivo',
        type: String,
        value: download => download.filename
      },
      {
        column: 'Quantidade de Downloads',
        type: String,
        value: download => download.total
      },
    ];
  
    const limit = 50;
  
    const numberOfRequisitions = Math.ceil(countData / limit);
    const allRequests = [];
  
    for (let i = 1; i <= numberOfRequisitions; i += 1) {
      const response = await getDownloads({
        idEvent,
        offset: i,
        limit,
      })
  
      allRequests.push(response.data.files);
      setTimeout(() => {}, 500);
    }
  
    const downloads = allRequests.flat();
  
    await writeXlsxFile(downloads, {
      schema,
      fileName: 'relatorio-arquivos.xlsx'
    })
  } catch (error) {
    console.log(error);
  }
}