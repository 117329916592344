import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getGruposByLogin } from "../../services/login";
import "./styled.css";
// componentes
import BodyCard from "./bodyCard";
import { data, data2 } from "./data";

export default function SenacTripulante() {
  const [user, setUser] = useState();
  const [searchParams] = useSearchParams();
  const [groups, setGroups] = useState(null);
  const [groups2, setGroups2] = useState(null);

  useEffect(() => {
    if (window.location.search !== "") {
      // var user = window.location.search.split("?");
      const idEvent = searchParams.get("idEvent");
      const idPart = searchParams.get("idPart");
      const native = searchParams.get("native");

      setUser({
        idEvent: idEvent,
        idPart: idPart,
        native: native,
      });
      getGroups(idPart);
    }
  }, []);

  async function getGroups(login) {
    const res = await getGruposByLogin(login);

    if (res.data) {
      const exists = res.data.filter(
        (item) =>
          item.grupos_id === "c7496ee0-0e8f-4eac-abd0-89bf8a5ebb51" ||
          item.grupos_id === "b9900e8e-fa9c-4e96-9163-fc7e6bab826b"
      );

      if (exists?.length > 0) {
        setGroups(res.data);
      }

      // filtro pra outra listagem
      if (
        res.data.filter(
          (e) =>
            e.grupos_id === "96621740-4160-49e1-b485-bfee864f04fa" ||
            e.grupos_id === "b9900e8e-fa9c-4e96-9163-fc7e6bab826b"
        ).length > 0
      ) {
        setGroups2(res.data);
      }
    }
  }

  return (
    <section className="section-files-senactripulante">
      {groups || groups2 ? (
        <div className="div-files">
          {groups &&
            data?.map((item) => (
              <BodyCard
                user={user}
                data={item.link}
                type={"PDF"}
                fileName={`${item.subtitle}.pdf`}
                title={item.title}
                description={[{ data: item.subtitle }]}
                id={"dee9bd4d-4d9d-40c4-ba3e-e340a865cc53"}
                button={"Download"}
              />
            ))}

          {groups2 &&
            data2?.map((item) => (
              <BodyCard
                user={user}
                data={item.link}
                type={"PDF"}
                // fileName={`${item.subtitle}.pdf`}
                fileName={
                  item.subtitle ? item.subtitle + ".pdf" : item.title + ".pdf"
                }
                title={item.title}
                description={[{ data: item.subtitle }]}
                id={"dee9bd4d-4d9d-40c4-ba3e-e340a865cc53"}
                button={"Download"}
                colorBtn={"#e4b424"}
              />
            ))}
        </div>
      ) : (
        <div style={{ color: "white" }}>
          <h3>Nenhum arquivo encontrado</h3>
        </div>
      )}
    </section>
  );
}
