import axios from "axios";
const urlBaseRankig =
  "https://gateway-admin-plus.sistemainteegra.com.br/api-admin-ranking";

// pontuar
async function createEarnedPoints(dataRanking, event, login) {
  const data = JSON.stringify({ ...dataRanking });
  var config = {
    method: "post",
    url: `${urlBaseRankig}/events/${event}/logins/${login}/earnedpointcreate`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

export { createEarnedPoints };
