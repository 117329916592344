import React, { useState, useEffect } from "react";
import "./styled.css";
// service
import { createEarnedPoints } from "../../services/points";
import { postDownload } from "../../services/download";
// detectar os
import { isIOS } from "react-device-detect";
import ModalContent from "./modalContent";

export default function BodyCard(props) {
  const { data, user } = props;
  const [disabled, setDisabled] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [contentType, setContentType] = useState("")

  const toggle = () => setIsOpen(!isOpen)

  const openModal = (type) => {
    setContentType(type)
    setIsOpen(true)
  }

  async function downloadApi(id, type) {
    await postDownload(user.idEvent, user.idPart, id, data.title + "-" + type)
      .then((res) => console.log("download", res))
      .catch((error) => console.log("erro download", error));
  }

  const addPoints = (code, id, type) => {
    const dataRanking = {
      code: code,
      positive: true,
      log: "download",
    };

    createEarnedPoints(dataRanking, user.idEvent, user.idPart)
      .then((res) => console.log("points", res))
      .catch((error) => console.log("erro points", error));

      downloadApi(id, type);
  }

  function downloadFile({ type, dataLink }) {
    if (user.native) {
      // verificar se é pelo app
      window.postMessage(
        JSON.stringify({
          message: "base64ToDownload",
          data: dataLink,
          name: `${data.title}${type === "pdf" ? ".pdf" : ".mp4"}`,
        }),
        "*"
      );
      window.parent.ReactNativeWebView.postMessage(
        JSON.stringify({
          message: "base64ToDownload",
          data: dataLink,
          name: `${data.title}${type === "pdf" ? ".pdf" : ".mp4"}`,
        })
      );
    } else {
      console.log({dataLink, data})
      // se n tiver o parâmetro, baixa pelo web
      var link = document.createElement("a");
      link.download = data.title;
      link.target = "_blank";
      link.href = dataLink;
      link.click();
    }
  }

  useEffect(() => {
    if (new Date(data.date) < new Date()) {
      setDisabled(false);
    }
  }, []);

  function redirectToStore() {
    if (isIOS) {
      window.location = "https://apps.apple.com/br/app/power-apps/id1047318566";
    } else {
      window.open(
        "https://play.google.com/store/apps/details?id=com.microsoft.msapps",
        "_blank"
      );
    }
  }

  if (!disabled)
    return (
      <>
        <div className="div-doc">
          <a className="a-title-item">{data.title}</a>
          {data.video && (
            <a
              onClick={() => {
                openModal("video");
                addPoints(data.codeVideo, data.videoId, "video");
              }}
              className={`button-doc`}
            >
              Vídeo
            </a>
          )}
          {data.video2 && (
            <a
              onClick={() => {
                openModal("video2");
                addPoints(data.codeVideo2, data.videoId, "video2");
              }}
              className={`button-doc`}
            >
              Vídeo 2
            </a>
          )}
          {data.pdf && (
            <a
              onClick={() =>
                {
                  openModal("pdf")
                  addPoints(data.codePdf, data.pdfId, "pdf");
                  // downloadFile({type: "pdf", dataLink: data.pdf})
                }
              }
              className={`button-doc`}
            >
              PDF
            </a>
          )}
          {data.downloadApp && (
            <a
              onClick={() => {
                downloadApi(data.downloadAppId, "linkStore");
                redirectToStore();
              }}
              className={`button-doc`}
            >
              Baixar Power Apps
            </a>
          )}
          {isOpen && (
            <ModalContent 
              toggle={toggle} 
              isOpen={isOpen} 
              data={data} 
              contentType={contentType} 
              downloadFile={downloadFile} 
            />
          )}
        </div>
      </>
    );
}
