import { BrowserRouter, Routes, Route } from "react-router-dom";
//styles
import "bootstrap/dist/css/bootstrap.css";

//pages
import Files from "./pages/files";
import Danone from "./pages/danone";
import Senac from "./pages/senac";
import ReportFile from "./pages/reportFile/reportFile";
import ReportParticipants from "./pages/reportParticipant/reportParticipant";
import SenacTripulante from "./pages/senacTripulante";
import Cnvkenvue from "./pages/cnvkenvue";

function App() {
  return (
    <div>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta2/css/all.min.css"
      />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Files />} />
          <Route path="/danone" element={<Danone />} />
          <Route path="/senac" element={<Senac />} />
          <Route path="/reportFile" element={<ReportFile />} />
          <Route path="/reportParticipant" element={<ReportParticipants />} />
          <Route path="/senactripulante" element={<SenacTripulante />} />
          <Route path="/cnvkenvue" element={<Cnvkenvue />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
