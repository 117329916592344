import axios from "axios";

const urlBase = "https://gateway-admin-plus.sistemainteegra.com.br/api-admin-files";

function postDownload(eventId, loginId, fileId, fileName) {
  var data = JSON.stringify({
    eventId,
    loginId,
    fileId,
    fileName,
  });

  var config = {
    method: "post",
    url: `${urlBase}/download`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export { postDownload };
