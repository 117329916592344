import React, { useEffect } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { IoMdDownload } from "react-icons/io";
import "./styled.css";

import { Viewer, Worker } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';
import { useWidth } from "../../utils/windowResize";
import { corsEndpoint } from "../../utils/constants";

export default function ModalContent({ isOpen, toggle, data, contentType, downloadFile }) {

  const widthSize = useWidth()
  const widthSizePDF = widthSize < 456 ? "340px" : "450px"

  const verifyDownload = () => {
    if (contentType === "pdf") {
     return downloadFile({
      type: "pdf",
      id: data.pdfId,
      dataLink: data.pdf,
      })
    }

    if (contentType === "video2") {
      return downloadFile({
        type: "video2",
        id: data.videoId,
        dataLink: data.video2,
      })
    }

    return downloadFile({
      type: "video",
      id: data.videoId,
      dataLink: data.video,
    })
  }

  return (
    <aside className="content-modal">
      <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{data.title}</ModalHeader>
        <ModalBody>
          {contentType === "video" && data.video && (
            <iframe
              src={data.video}
              width={"100%"}
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              title={data.title}
            ></iframe>
          )}

          {contentType === "video2" && data.video2 && (
            <iframe
              src={data.video2}
              width={"100%"}
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              title={data.title}
            ></iframe>
          )}

          {contentType === "pdf" && data.pdf && (
            <div style={{ width: widthSizePDF , height: '400px' }}>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer fileUrl={`${corsEndpoint}${data.pdf}`} />
              </Worker>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={verifyDownload}>
            <IoMdDownload color="#fff" size={20} /> Baixar conteúdo
          </Button>
        </ModalFooter>
      </Modal>
    </aside>
  )
}