import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "./styled.css";

//component
import BodyCard from "./bodyCard";

//GET FILE BASE64 https://easy64.org/convert-file-to-base64/

export default function Files() {
  const [user, setUser] = useState();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (window.location.search != "") {
      // var user = window.location.search.split("?");
      const idEvent = searchParams.get("idEvent");
      const idPart = searchParams.get("idPart");
      const native = searchParams.get("native");

      setUser({
        idEvent: idEvent,
        idPart: idPart,
        native: native,
      });
    }
  }, []);
  return (
    <div className="section-files">
      <div className="div-files">
        <BodyCard
          user={user}
          data={
            "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_FDC/XP-Empresas-One-Pager.pdf"
          }
          type={"PDF"}
          fileName="XPInvestimentos.pdf"
          title={"XP Investimentos"}
          description={[{ data: "O que fazemos?" }]}
          id={"db873939-23b0-456a-aadc-d757895adb7e"}
          button={"Download"}
        />

        <BodyCard
          user={user}
          data={"https://forms.office.com/r/HhQ5B0Wp5N"}
          type={"Link"}
          fileName="Link XP Investimentos"
          title={"XP Investimentos"}
          description={[
            { data: "Quer Saber mais sobre o XP Empresas?" },
            {
              data: "Alan Paes, CFP® l (11) 3526-0205 alan.paes@xpi.com.br",
            },
          ]}
          id={"58438fbf-f1c6-48e8-90b6-86d9beee5da6"}
          button={"Acesse"}
        />

        <BodyCard
          user={user}
          data={
            "https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2022/98d40824-acfa-47cd-82b0-4eded6ba169c.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2089884190&Signature=WDcl6SZVoE9My7OLpmT2V9lcD1A%3D"
          }
          type={"PDF"}
          fileName="XP Empresas.pdf"
          title={"XP Investimentos"}
          description={[{ data: "XP empresas" }]}
          id={"db873939-23b0-456a-aadc-d757895adb7e"}
          button={"Download"}
        />

        <BodyCard
          user={user}
          data={
            "https://cloud.gf.localiza.com/gf_e-book_sustentabilidade?utm_source=espaco-fdc&utm_medium=app&utm_campaign=forum-fdc-22"
          }
          type={"E-book"}
          fileName="E-book Localiza"
          title={"Localiza"}
          description={[
            { data: "Como mensurar a Sustentabilidade em sua empresa?" },
          ]}
          id={"db873939-23b0-456a-aadc-d757895adb7e"}
          button={"Acesse"}
        />

        <BodyCard
          user={user}
          data={"https://hubs.li/Q01nb-N-0"}
          type={"E-book"}
          fileName="E-book Siteware"
          title={"Siteware"}
          description={[{ data: "Maturidade de Gestão" }]}
          id={"d0eeaaa2-853f-468c-80d4-8c3306782b9d"}
          button={"Acesse"}
        />

        <BodyCard
          user={user}
          data={"https://hubs.li/Q01nb_bg0"}
          type={"Quiz"}
          fileName="Quiz Siteware"
          title={"Siteware"}
          description={[{ data: "Gestão conectada" }]}
          id={"d0eeaaa2-853f-468c-80d4-8c3306782b9d"}
          button={"Responda"}
        />

        <BodyCard
          user={user}
          data={"https://spoti.fi/3UK1TSa"}
          type={"Podcast"}
          fileName="Podcast FDC"
          title={"FDC"}
          description={[
            {
              data: "Saber em Ação #3 - Fatores de alto crescimento das médias empresas",
            },
          ]}
          id={"a8a5194f-7104-4527-9fd5-f11024676103"}
          button={"Ouça agora"}
        />
      </div>
    </div>
  );
}
