import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Card, CardBody, Input, Table } from 'reactstrap';

import '../../style/reports.css';

import { createXlsx } from './createXlsx';
import { getDownloads } from '../../services/reports';
import Pagination from '../../components/pagination';

export default function ReportFile() {
  const [searchParams] = useSearchParams();

  const [downloads, setDownloads] = useState([]);
  const [search, setSearch] = useState('');

  //Pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const idEvent = searchParams.get('idEvent');

  const allParticipants = useCallback(async () => {
    const response = await getDownloads({
      search,
      idEvent,
      offset: currentPage,
      limit: itemsPerPage,
    });
    setDownloads(response.data.files);
    setTotalItems(response.data.total);
  }, [search, currentPage, itemsPerPage, idEvent]);

  useEffect(() => {
    allParticipants();
  }, [allParticipants]);

  return (
    <div className="report-container">
      <Card className="card-container">
        <CardBody>
          <div className="card-title">
            <h5>Relatório de downloads por arquivo</h5>
          </div>
          <div className="export-and-search-container">
            <Input
              className="search-input"
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value) > setCurrentPage(1)}
            />
            <Button color="success" onClick={() => createXlsx({idEvent, countData: totalItems})}>
              Exportar Relatório
            </Button>
          </div>
          <Table className="table mb-0 table" >
            <thead className="table-light">
              <tr>
                <th>Nome do Arquivo</th>
                <th>Quantidade de Downloads</th>
              </tr>
            </thead>
            <tbody>
              {downloads.length > 0
                && downloads.map((download, index) => (
                  <tr key={index}>
                    <td>{download.filename}</td>
                    <td>{download.total}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <div style={{display: 'flex', alignSelf: 'flex-end', marginRight: '5%'}}>
        <Pagination
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          totalItems={totalItems}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  )
}
