import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "./styled.css";
// componente
import BodyCard from "./bodyCard";

const data = [
  {
    title: "Farmacovigilância",
    date: "05/28/2024",
    video:
      "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_123687/Video/2024%20Seguran%C3%A7a%20de%20produto.mp4",
    codeVideo: "FARMACOVIGILANCIAVIDEO",
    pdf: "https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2024/28b4310d-d2e3-4293-baa5-23178cda2f2f.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2142507527&Signature=i7CJR7xEI9YbaqD9lH0vh8SkA7Q%3D",
    codePdf: "FARMACOVIGILANCIAPDF",
    videoId: "da2e63d2-e2c8-4924-a89f-2f2627d7f1e8",
    pdfId: "a26231eb-2789-424e-8406-d573347034dc",
    downloadApp: true,
    downloadAppId: "5b298967-61f8-4804-9072-da949e446508",
    downloadAndroid:
      "https://play.google.com/store/apps/details?id=com.microsoft.msapps",
    downloadIos: "https://apps.apple.com/br/app/power-apps/id1047318566",
  },
  {
    title: "Privacidade & Proteção de Dados Pessoais",
    date: "05/29/2024",
    video:
      "https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2024/cdb028b6-4506-4ec4-ba07-c78a4235008a.mp4?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2142507640&Signature=FoJIRQAGOzVk2ds1398K1H%2FnGU4%3D",
    codeVideo: "PRIVACIDADEVIDEO",
    pdf: "https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2024/99149bac-e997-42f0-9395-db35dd27c483.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2142507602&Signature=00GgMqfnxugYNeYk69hpM4%2FXpBg%3D",
    codePdf: "PRIVACIDADEPDF",
    videoId: "f2d430d7-986f-4298-a99e-ed2184c1c377",
    pdfId: "535ff2c4-0a07-4423-b6d2-2ed44e715379",
  },
  {
    title:
      "Tecnologia como aliada na proteção de dados pessoais e informações da Kenvue",
    date: "06/03/2024",
    video: "",
    codeVideo: "",
    pdf: "https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2024/efab59f9-fbbf-4a4a-8145-fbaca79049bf.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2143192636&Signature=gdx8k2h4cEl63Lj32RDS00BqLfY%3D",
    codePdf: "TECNOLOGIAPDF",
    videoId: "ecf4eeeb-2c54-431a-b48a-c498101eef5e",
    pdfId: "2472b292-513f-48ec-b91a-3f37502a6eb8",
  },
  {
    title: "ER/LR",
    date: "06/04/2024",
    video: "",
    codeVideo: "",
    pdf: "https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2024/df4b9f72-b28a-4c35-804a-8681aa20a2bf.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2143463773&Signature=haSPG2Y9gGCaNYCmef4%2FQEgjT6Q%3D",
    codePdf: "RECURSOSPDF",
    videoId: "2e11a250-9f5a-4655-8268-26c820b615cb",
    pdfId: "6e026604-4bf8-4270-9ec1-53ee20b41b24",
  },
  {
    title: "Assegurando nosso alto padrão de Ética & Compliance",
    date: "06/05/2024",
    video: "",
    codeVideo: "",
    pdf: "https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2024/fb79ffda-cd63-42e1-822e-3f9e97cf8b10.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2143019601&Signature=dTzXkVy9onMPVLiuhCQVkzUXKDU%3D",
    codePdf: "ASSEGURANDOPDF",
    videoId: "57c6dc37-a409-4b9a-b9ed-330d4a7fe0c7",
    pdfId: "62a4a6c4-aeb3-4510-b21f-2b5cea4decbb",
  },
  {
    title: "Segurança é você quem Faz!",
    date: "06/06/2024",
    video: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_123687/Video/Kenvue%20-%20Seguran%C3%A7a%20%C3%A9%20voc%C3%AA%20quem%20faz.mp4",
    codeVideo: "SEGURANCAVIDEO1",
    video2: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_123687/Video/Kenvue%20Road%20Safety%20-%20Seguran%C3%A7a%20em%20movimento.mp4",
    codeVideo2: "SEGURANCAVIDEO2",
    pdf: "",
    codePdf: "",
    videoId: "8933cece-0759-4a67-a8f2-fc55e14c3a72",
    pdfId: "c69c2915-72e7-468d-89ce-03f7295503d9",
  },
];

export default function Cnvkenvue() {
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (window.location.search !== "") {
      const idEvent = searchParams.get("idEvent");
      const idPart = searchParams.get("idPart");
      const native = searchParams.get("native");

      setUser({
        idEvent: idEvent,
        idPart: idPart,
        native: native,
      });
    }
  }, []);

  return (
    <section className="section-kenvue">
      <div className="div-list-doc">
        {data.map((e, i) => (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
            key={i}
          >
            <BodyCard data={e} user={user} />
          </div>
        ))}
      </div>
    </section>
  );
}
