import axios from 'axios';
import URL_Base from './urls';

async function getParticipants({ idEvent, search, offset, limit }) {
  try {
    const config = {
      method: 'GET',
      url: `${URL_Base.participants}/${idEvent}/?offset=${offset}&limit=${limit}${search ? `&search=${search}` : ''}`,
    };
  
    const response = await axios(config);

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
}

async function getDownloads({ idEvent, search, offset, limit }) {
  try {
    const config = {
      method: 'GET',
      url: `${URL_Base.downloads}/${idEvent}/?offset=${offset}&limit=${limit}${search ? `&search=${search}` : ''}`,
    };
  
    const response = await axios(config);

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export { getParticipants, getDownloads };
