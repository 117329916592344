import React, { useState } from "react";
import "./styled.css";
// service
import { postDownload } from "../../services/download";

export default function BodyCard(props) {
  const [download, setDownload] = useState(false);

  async function send() {
    // enviar dados pra api
    await postDownload(
      props.user.idEvent,
      props.user.idPart,
      props.id,
      props.fileName
    )
      .then((response) => {
        console.log("ok", response);
      })
      .catch((error) => {
        console.log("erro", error);
      });

    //se for link externo e ios
    if (download) {
      window.location = props.data;
      return;
    } else {
      if (
        props.type === "Link" ||
        props.type === "E-book" ||
        props.type === "Quiz" ||
        props.type === "Podcast"
      ) {
        var link = document.createElement("a");
        link.href = props.data;
        link.target = "_blank";
        link.click();
      } else {
        // verificar se está web ou mobile pelo parâmetro
        if (props.user.native) {
          // se tiver o paramêtro passado pelo app, baixa pelo app
          window.postMessage(
            JSON.stringify({
              message: "base64ToDownload",
              data: props.data,
              name: props.fileName,
            }),
            "*"
          );
          window.parent.ReactNativeWebView.postMessage(
            JSON.stringify({
              message: "base64ToDownload",
              data: props.data,
              name: props.fileName,
            })
          );
        } else {
          // se n tiver o parâmetro, baixa pelo web
          var link = document.createElement("a");
          link.download = props.fileName;
          link.target = "_blank";
          link.href = props.data;
          link.click();
        }
      }
    }
  }
  return (
    <div className="card-files">
      <div className="items-file-senactripulante">
        <div>
          <h3>{props.title}</h3>
          <div>
            {props.description.map((desc) => (
              <p className="subtitle-files">{desc.data}</p>
            ))}
          </div>
        </div>

        <a
          className="btn-download-senactripulante"
          onClick={() => send()}
          style={{ backgroundColor: props.colorBtn && props.colorBtn }}
        >
          {props.button}
        </a>
      </div>
    </div>
  );
}
