export const data = [
  {
    title: "Atuação Gerencial",
    subtitle: "Gestão 4.0",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/ATUACAO%20GERENCIAL/Gest%C3%A3o%204.0.pdf",
  },

  {
    title: "Atuação Gerencial",
    subtitle: "Líder conectado, uma gestão eficaz",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/ATUACAO%20GERENCIAL/L%C3%ADder%20conectado%2C%20uma%20gest%C3%A3o%20eficaz.pdf",
  },

  {
    title: "Atuação Gerencial",
    subtitle: "Sustentabilidade, conhecer, praticar e transformar",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/ATUACAO%20GERENCIAL/Sustentabilidade%2C%20conhecer%2C%20praticar%20e%20transformar.pdf",
  },

  {
    title: "Comunicação Institucional",
    subtitle: "Conecta em Ação, destaques da primeira fase",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/COMUNICACAO%20INSTITUCIONAL/Conecta%20em%20A%C3%A7%C3%A3o%2C%20destaques%20da%20primeira%20fase.pdf",
  },

  {
    title: "Comunicação Institucional",
    subtitle: "Eventos Corporativos como modelo de negócio",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/COMUNICACAO%20INSTITUCIONAL/Eventos%20Corporativos%20como%20modelo%20de%20neg%C3%B3cio.pdf",
  },

  {
    title: "Comunicação Institucional",
    subtitle: "Marketing de Produtos e Serviços",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/COMUNICACAO%20INSTITUCIONAL/Marketing%20de%20Produtos%20e%20Servi%C3%A7os.pdf",
  },

  {
    title: "Comunicação Institucional",
    subtitle: "Política de Comunicação do Sistema Comércio",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/COMUNICACAO%20INSTITUCIONAL/Pol%C3%ADtica%20de%20Comunica%C3%A7%C3%A3o%20do%20Sistema%20Com%C3%A9rcio.pdf",
  },

  {
    title: "Desenvolvimento de Negócios",
    subtitle: "Programa Atena",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/DESENVOLVIMENTO%20DE%20NEGOCIOS/Programa%20Atena.pdf",
  },

  {
    title: "Desenvolvimento de Negócios",
    subtitle: "Tendência de dados para orientar negócios",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/DESENVOLVIMENTO%20DE%20NEGOCIOS/Tend%C3%AAncia%20de%20dados%20para%20orientar%20neg%C3%B3cios.pdf",
  },

  {
    title: "Desenvolvimento de Negócios",
    subtitle: "Uma jornada na análise de dados",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/DESENVOLVIMENTO%20DE%20NEGOCIOS/Uma%20jornada%20na%20an%C3%A1lise%20de%20dados%20.pdf",
  },

  {
    title: "Desenvolvimento de Negócios",
    subtitle: "Inovação 360 e Mapa Setorial do Setor",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/DESENVOLVIMENTO%20DE%20NEGOCIOS/Inova%C3%A7%C3%A3o%20360%20e%20Mapa%20Setorial%20do%20Setor.pdf",
  },

  {
    title: "Relações Institucionais",
    subtitle: "Atuação Institucional Assertiva",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/RELACOES%20INSTITUCIONAIS/Atua%C3%A7%C3%A3o%20Institucional%20Assertiva.pdf",
  },

  {
    title: "Relações Institucionais",
    subtitle: "Networking com pessoas públicas",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/RELACOES%20INSTITUCIONAIS/Networking%20com%20pessoas%20p%C3%BAblicas.pdf",
  },

  {
    title: "Relações Institucionais",
    subtitle: "Panorama da Reforma Tributária",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/RELACOES%20INSTITUCIONAIS/Panorama%20da%20Reforma%20Tribut%C3%A1ria.pdf",
  },

  {
    title: "Relações Institucionais",
    subtitle:
      "Políticas Públicas e Projetos de Lei,  como identificar e atuar proativamente",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/RELACOES%20INSTITUCIONAIS/Pol%C3%ADticas%20P%C3%BAblicas%20e%20Projetos%20de%20Lei%2C%20%20como%20identificar%20e%20atuar%20proativamente.pdf",
  },

  {
    title: "Relações Sindicais",
    subtitle: "Aperfeiçoamento da Gestão Comercial",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/RELACOES%20SINDICAIS/Aperfei%C3%A7oamento%20da%20Gest%C3%A3o%20Comercial.pdf",
  },

  {
    title: "Relações Sindicais",
    subtitle: "Aperfeiçoamento de Dirigentes Sindicais",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/RELACOES%20SINDICAIS/Aperfei%C3%A7oamento%20de%20Dirigentes%20Sindicais%20.pdf",
  },

  {
    title: "Relações Sindicais",
    subtitle:
      "Comitê Trabalhista e Sindical, Apresentação Leandro Almeida Conceta 2023",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/RELACOES%20SINDICAIS/Comit%C3%AA%20Trabalhista%20e%20Sindical%20_%20Apresenta%C3%A7%C3%A3o_Leandro_Almeida_Conceta_2023.pdf",
  },

  {
    title: "Relações Sindicais",
    subtitle: "Enquadramento Sindical",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/RELACOES%20SINDICAIS/Enquadramento%20Sindical%20.pdf",
  },

  {
    title: "Representação",
    subtitle:
      "Câmaras do Comércio, um catalisador para o Sistema. O valor da escuta",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/REPRESENTACAO/C%C3%A2maras%20do%20Com%C3%A9rcio%2C%20um%20catalisador%20para%20o%20Sistema.%20O%20valor%20da%20escuta..pdf",
  },

  {
    title: "Representação",
    subtitle: "Defesa de Interesses do Sistema Comércio",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/REPRESENTACAO/Defesa%20de%20Interesses%20do%20Sistema%20Com%C3%A9rcio%20.pdf",
  },

  {
    title: "Representação",
    subtitle: "Importância do Papel do Representante",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/REPRESENTACAO/Import%C3%A2ncia%20do%20Papel%20do%20Representante.pdf",
  },

  {
    title: "Representação",
    subtitle: "Turismo como fator de desenvolvimento econômico",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/REPRESENTACAO/Turismo%20como%20fator%20de%20desenvolvimento%20econ%C3%B4mico.pdf",
  },

  {
    title: "Comunicação Institucional",
    subtitle: "Atuação Sindical nas Mídias Sociais",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/CONECTA/COMUNICACAO%20INSTITUCIONAL/Atua%C3%A7%C3%A3o%20Sindical%20nas%20M%C3%ADdias%20Sociais.pdf",
  },
];
export const data2 = [
  {
    title: "Entendendo o Sicomercio",
    subtitle: "",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/PL_12.07_11h_Entendendo%20o%20Sicomercio_Ivo.pdf",
  },

  {
    title: "Negociação Coletiva",
    subtitle: "",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/PL_13.07_10h_Sicomercio_Negocia%C3%A7%C3%A3o%20Coletiva_Min%20Douglas%20Alencar.pdf",
  },

  {
    title: "Atuação Gerencial",
    subtitle: "Atendimento automatizado via Whatsapp",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Atuacao%20Gerencial/AG%2014%2007%2014h_FBHA_SindiRio_Atendimento%20automatizado%20via%20Whatsapp.pdf",
  },

  {
    title: "Atuação Gerencial",
    subtitle: "Recrutamento e seleção",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Atuacao%20Gerencial/AG_12.07_14h_GERH_ET_Sicom%C3%A9rcio_Recrutamento%20e%20sele%C3%A7%C3%A3o_Danielle-Renata-Andre.pdf",
  },

  {
    title: "Atuação Gerencial",
    subtitle: "ESG Sustentabilidade e Economia Circular",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Atuacao%20Gerencial/AG_12.07_15h_DC_ET_Sicomercio_ESG%20Sustentabilidade%20e%20Economia%20Circular_Fernanda%20Ramos.pdf",
  },

  {
    title: "Atuação Gerencial",
    subtitle: "Gestão do conhecimento e informações sindicais",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Atuacao%20Gerencial/AG_12.07_16h_GDI_ET_Sicomercio_Gest%C3%A3o%20do%20conhecimento%20e%20informa%C3%A7%C3%B5es%20sindicais_Bernanrdo%20Palma.pdf",
  },

  {
    title: "Atuação Gerencial",
    subtitle: "Agilidade como ferramenta estratégica do negócio",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Atuacao%20Gerencial/AG_13.07_14h_GPLAN_ET_Sicomercio_Agilidade%20como%20ferramenta%20estrat%C3%A9gica%20do%20neg%C3%B3cio_Rafaela%20Rio%20-%20v2.pdf",
  },

  {
    title: "Atuação Gerencial",
    subtitle: "Desdobramento da Estratégia",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Atuacao%20Gerencial/AG_13.07_15h_GPLAN_ET_Sicom%C3%A9rcio_Desdobramento%20da%20Estrat%C3%A9gia_Marina%20Maciel%20e%20%C3%89rica%20Bastos%20-%20v2.pdf",
  },

  {
    title: "Atuação Gerencial",
    subtitle: "LGPD e aspectos_parte 01 Camila Blanco",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Atuacao%20Gerencial/AG_13.07_16h_GETI_ET_Sicomercio_LGPD%20e%20aspectos_parte%2001%20Camila%20Blanco.pdf",
  },

  {
    title: "Atuação Gerencial",
    subtitle: "LGPD e aspectos_parte 02 Cleise Alonso",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Atuacao%20Gerencial/AG_13.07_16h_GETI_ET_Sicomercio_LGPD%20e%20aspectos_parte%2002%20Cleise%20Alonso.pdf",
  },

  {
    title: "Atuação Gerencial",
    subtitle: "LGPD e aspectos_parte 03 Carlos Henrique",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Atuacao%20Gerencial/AG_13.07_16h_GETI_ET_Sicomercio_LGPD%20e%20aspectos_parte%2003%20Carlos%20Henrique%20-%20v2.pdf",
  },

  {
    title: "Atuação Gerencial",
    subtitle: "SINEXA_Sistema 100% Web",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Atuacao%20Gerencial/AG_14%2007_15h_SC_Sirecom%20Fpolis_SINEXA_Sistema%20100%25%20Web%20.pdf",
  },

  {
    title: "Atuação Gerencial",
    subtitle: "Sindicato Forte Comércio Forte",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Atuacao%20Gerencial/AG_14%2007_16h30_GO_Fecom%C3%A9rcio_Sindicato%20Forte%20Com%C3%A9rcio%20Forte.pdf",
  },

  {
    title: "Comunicação Institucional",
    subtitle: "Pesquisa de Reputação e Imagem CNC",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Comunicacao%20Institucional/CI_12.07_14h_ET_Sicomercio_Pesquisa%20de%20Reputa%C3%A7%C3%A3o%20e%20Imagem%20CNC_Elienai.pdf",
  },

  {
    title: "Comunicação Institucional",
    subtitle: "Planejamento de Comunicação na Prática",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Comunicacao%20Institucional/CI_12.07_14h_Planejamento%20de%20Comunica%C3%A7%C3%A3o%20na%20Pr%C3%A1tica_Andreia%20Tamiozzo.pdf",
  },

  {
    title: "Comunicação Institucional",
    subtitle: "Política de Comunicação",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Comunicacao%20Institucional/CI_12.07_15h_ET_Sicomercio_Politica%20de%20Comunica%C3%A7%C3%A3o_Rennan%20FSB.pdf",
  },

  {
    title: "Comunicação Institucional",
    subtitle: "Marketing Associativo",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Comunicacao%20Institucional/CI_12.07_16h_ET_Sicomercio_Marketing%20Associativo_Elienai%20e%20Fabio.pdf",
  },

  {
    title: "Comunicação Institucional",
    subtitle: "Produção de eventos - Case The Match",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Comunicacao%20Institucional/CI_13.07_15h_ET_Sicomercio_Produ%C3%A7%C3%A3o%20de%20eventos-Case%20The%20Match_Maria%20Fecomercio%20BA.pdf",
  },

  {
    title: "Comunicação Institucional",
    subtitle: "Produção de eventos - Giselle Monteiro",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Comunicacao%20Institucional/CI_13.07_15h_ET_Sicomercio_Produ%C3%A7%C3%A3o%20de%20eventos_Gislle%20Monteiro.pdf",
  },

  {
    title: "Comunicação Institucional",
    subtitle: "Vendas para obter resultados",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Comunicacao%20Institucional/CI_13.07_16h_ET_Sicomercio_Vendas%20para%20obter%20resultados_Andre%20Brigag%C3%A3o.pdf",
  },

  {
    title: "Comunicação Institucional",
    subtitle: "Marketing de Conteúdo",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Comunicacao%20Institucional/CI_14%2007_14h_GO_Sindimaco_Marketing%20de%20Conte%C3%BAdo.pdf",
  },

  {
    title: "Comunicação Institucional",
    subtitle: "Programa Sicomércio Camaçari com você",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Comunicacao%20Institucional/CI_14%2007_15h_BA_Sicom%C3%A9rcio%20Cama%C3%A7ari_Programa%20Sicom%C3%A9rcio%20Cama%C3%A7ari%20com%20voc%C3%AA.pdf",
  },

  {
    title: "Comunicação Institucional",
    subtitle: "Sindilojas Itajaí - Área do Associado",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Comunicacao%20Institucional/CI_14%2007_16h30_SC_Sindilojas%20Itaja%C3%AD_%C3%81rea%20do%20Associado.pdf",
  },

  {
    title: "Desenvolvimento de Negócios",
    subtitle: "Mapa do Comércio",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Desenvolvimento%20de%20Negocios/DN%2014%2007_15h_SC_Sicom_Mapa%20do%20Com%C3%A9rcio.pdf",
  },

  {
    title: "Desenvolvimento de Negócios",
    subtitle:
      "Aperfeiçoamento da gestão comercial nas entidades sindicais do comércio",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Desenvolvimento%20de%20Negocios/DN_12.07_14h_GEF_ET_Sicomercio_Gest%C3%A3o%20Com%20no%20Sind_Gabriel%20e%20Fecom%20MG%20-%20v2.pdf",
  },

  {
    title: "Desenvolvimento de Negócios",
    subtitle: "Mapeamento Setorial",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Desenvolvimento%20de%20Negocios/DN_12.07_15h_DEIN_ET_Sicomercio_Mapeamento%20Setorial_Fabio%20Bentes%20-%20v2.pdf",
  },

  {
    title: "Desenvolvimento de Negócios",
    subtitle:
      "Sesc e Senac: o que as instituições têm a oferecer a sindicatos e associadas",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Desenvolvimento%20de%20Negocios/DN_12.07_16h_SENAC_ET_Sicomercio_Rela%C3%A7%C3%B5es%20Institucionais_Antonio%20Henrique.pdf",
  },

  {
    title: "Desenvolvimento de Negócios",
    subtitle: "A vida acontece com o Sesc",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Desenvolvimento%20de%20Negocios/DN_12.07_16h_SESC_ET_Sicomercio_Rela%C3%A7%C3%B5es%20Institucionais_Marise%20Coelho.pdf",
  },

  {
    title: "Desenvolvimento de Negócios",
    subtitle: "Tributação de Serviços em Sindicatos",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Desenvolvimento%20de%20Negocios/DN_13.07_15h_DJS_ET_Sicomercio_Tributa%C3%A7%C3%A3o%20de%20Servi%C3%A7os%20em%20Sindicatos_Cacito%20Esteves.pdf",
  },

  {
    title: "Desenvolvimento de Negócios",
    subtitle: "Pesquisas CNC",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Desenvolvimento%20de%20Negocios/DN_13.07_16h_DEIN_ET_Sicomercio_Pesquisas%20CNC_Izis%20Janote.pdf",
  },

  {
    title: "Desenvolvimento de Negócios",
    subtitle: "Cursos SincoFarma",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Desenvolvimento%20de%20Negocios/DN_14%2007_14h_SP_SincoFarma_Cursos%20SincoFarma.pdf",
  },

  {
    title: "Desenvolvimento de Negócios",
    subtitle: "Descubra Minas",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Desenvolvimento%20de%20Negocios/DN_14%2007_16h30_MG_Fecom%C3%A9rcio_Descubra%20Minas.pdf",
  },

  {
    title: "Relações Institucionais",
    subtitle: "Atuação Institucional Assertiva",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Relacoes%20Institucionais/RI_12.07_14h_DRI_ET_Sicomercio_Atua%C3%A7%C3%A3o%20Institucional%20Assertiva_Bruna-Felipe%20e%20Mizael%20-%20v2.pdf",
  },

  {
    title: "Relações Institucionais",
    subtitle: "Estratégias de networking com pessoas públicas",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Relacoes%20Institucionais/RI_12.07_15h_DRI__ET_Sicomercio_Estrat%C3%A9gias%20de%20networking%20com%20pessoas%20p%C3%BAbl_Elielson%20e%20Reiner%20-%20v2.pdf",
  },

  {
    title: "Relações Institucionais",
    subtitle: "Políticas Públicas - identificar atuar proativamente",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Relacoes%20Institucionais/RI_12.07_16h_DRI_ET_Sicomercio_Pol%C3%ADticas%20P%C3%BAblicas_identificar%20atuar%20proativamente_Bruna%20Aline.pdf",
  },

  {
    title: "Relações Institucionais",
    subtitle: "Projetos de lei - como identificar atuar proativamente",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Relacoes%20Institucionais/RI_13.07_14h_DRI_ET_Sicomercio_Projetos%20de%20lei_como%20identificar%20atuar%20proativamente_ThaisAna.pdf",
  },

  {
    title: "Relações Institucionais",
    subtitle: "Como comunicar a atuação nos Poderes",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Relacoes%20Institucionais/RI_13.07_15h_DRI_ET_Sicomercio_Como%20comunicar%20a%20atua%C3%A7%C3%A3o%20nos%20Poderes_Larissa%20e%20Moises.pdf",
  },

  {
    title: "Relações Institucionais",
    subtitle: "Agenda Institucional na Prática",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Relacoes%20Institucionais/RI_13.07_16h_DRI_ET_Sicomercio_Agenda%20Institucional%20na%20Pr%C3%A1tica_Felipe%20e%20Douglas.pdf",
  },

  {
    title: "Relações Institucionais",
    subtitle: "Atuação junto aos Poderes",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Relacoes%20Institucionais/RI_14%2007_14h_MG_Sindcom%C3%A9rcio%20Santa%20Luzia_Atua%C3%A7%C3%A3o%20junto%20aos%20Poderes.pdf",
  },

  {
    title: "Relações Institucionais",
    subtitle: "Patrimônio Cultural e Imaterial Gastronômico",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Relacoes%20Institucionais/RI_14%2007_15h_GO_SindPitidog_Patrim%C3%B4nio%20Cultural%20e%20Imaterial%20Gastron%C3%B4mico.pdf",
  },

  {
    title: "Relações Institucionais",
    subtitle: "Labi Secovi Legis",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Relacoes%20Institucionais/RI_14%2007_16h30_RJ_Secovi_Labi%20Secovi%20Legis.pdf",
  },

  {
    title: "Relações Sindicais",
    subtitle: "A Nova Liderança",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Relacoes%20Sindicais/RS_12.07_14h_ET_Sicomercio_A%20Nova%20Lideran%C3%A7a_Rosangela%20Angones.pdf",
  },

  {
    title: "Relações Sindicais",
    subtitle: "Atuação Sindical Integrada",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Relacoes%20Sindicais/RS_12.07_16h_ET_Sicomercio_%20Atua%C3%A7%C3%A3o%20Sindical%20Integrada_Reginaldo%20e%20Fernando_Fecom%20MS.pdf",
  },

  {
    title: "Relações Sindicais",
    subtitle: "Organização Sindical",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Relacoes%20Sindicais/RS_13.07_14h_DJS_ET_%20Sicom%C3%A9rcio_Organiza%C3%A7%C3%A3o%20Sindical_Roberto%20Lopes%20-%20v2.pdf",
  },

  {
    title: "Relações Sindicais",
    subtitle: "Fomento do associativismo",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Relacoes%20Sindicais/RS_13.07_15h_DJS_ET_Sicomercio_Fomento%20do%20associativismo_Lisandra%20e%20Alain%20-%20v2.pdf",
  },

  {
    title: "Relações Sindicais",
    subtitle: "Programa Atena_Desenvolvimento Sindical Inovador",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Relacoes%20Sindicais/RS_13.07_16h_DJS_ET_Sicomercio_Programa%20Atena_Desenvolvimento%20Sindical%20Inovador_Mateus%20e%20Joao%20-%20v2.pdf",
  },

  {
    title: "Relações Sindicais",
    subtitle: "Sincofarn Itinerante",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Relacoes%20Sindicais/RS_14%2007_14h_RN_Sincofarn%20Itinerante.pdf",
  },

  {
    title: "Relações Sindicais",
    subtitle: "Humanizando o Digital",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Relacoes%20Sindicais/RS_14%2007_15h_RJ_Simerj_Humanizando%20o%20Digital.pdf",
  },

  {
    title: "Relações Sindicais",
    subtitle: "App Integração das Bases",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Relacoes%20Sindicais/RS_14%2007_16H_FBHA_Sicom%C3%A9rcio%20Niteroi_App%20Integra%C3%A7%C3%A3o%20das%20Bases.pdf",
  },

  {
    title: "Representação",
    subtitle: "Atuação integrada do turismo comércio e serviços",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Representacao/RP_12.07_14h_CETUR_ET_Sicomercio_Atua%C3%A7%C3%A3o%20integrada%20do%20turismo%20comercio%20e%20servi%C3%A7os_Marcia%20e%20Cassio.pdf",
  },

  {
    title: "Representação",
    subtitle: "Importância do Papel do Representante_parte 01 Cassia e Sergio",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Representacao/RP_12.07_15h_GGR_ET_Sicomercio_Import%C3%A2ncia%20do%20Papel%20do%20Representante_parte%2001_Oscar-Cassia%20e%20Sergio.pdf",
  },

  {
    title: "Representação",
    subtitle: "Importância do Papel do Representante_parte 02 Rodrigo Moreira",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Representacao/RP_12.07_15h_GGR_ET_Sicomercio_Import%C3%A2ncia%20do%20Papel%20do%20Representante_parte%2002_Rodrigo%20Moreira.pdf",
  },

  {
    title: "Representação",
    subtitle: "Câmaras Setoriais",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Representacao/RP_12.07_16h_ACBCS_ET_Sicomercio_C%C3%A2maras%20Setoriais_Andrea_Pedro%20Wahmann.pdf",
  },

  {
    title: "Representação",
    subtitle: "A Representatividade do Sistema Comércio",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Representacao/RP_13.07_14h_ET_Sicomercio_A%20Representatividade%20do%20Sistema%20Com%C3%A9rcio_Lilian_%20Sind%20Barra%20Mansa.pdf",
  },

  {
    title: "Representação",
    subtitle: "7 Perguntas e Respostas - ESG",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Representacao/RP_13.07_15h_DC_ET_Sicomercio_7%20Perguntas%20e%20Respostas%20-%20ESG_Fernanda%20Ramos%20-%20v2.pdf",
  },

  {
    title: "Representação",
    subtitle: "Defesa de interesses do Sistema Comercio",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Representacao/RP_13.07_16h_GGR_ET_Sicomercio_Defesa%20de%20interesses%20do%20Sistema%20Comercio_Oscar-Cassia%20e%20Sergio.pdf",
  },

  {
    title: "Representação",
    subtitle: "Sinca Jovem",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Representacao/RP_14%2007_14h_PR_Sinca%20Jovem.pdf",
  },

  {
    title: "Representação",
    subtitle: "Café com Empresário",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Representacao/RP_14%2007_15h_FENACON_SESCON-PB_Caf%C3%A9%20com%20Empres%C3%A1rio.pdf",
  },

  {
    title: "Representação",
    subtitle: "Instituto Profac",
    link: "https://s3.us-east-1.amazonaws.com/bucket.aws.private/App_110375/Documentos/SICOMERCIO/Representacao/RP_14%2007_16h30_MS_SEAC_Instituto%20Profac.pdf",
  },
];
